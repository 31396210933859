import React, {useEffect, useState} from 'react'
import {KTSVG, KTCardBody, KTCard} from '../../_metronic/helpers'
import './style.css'
import logoImg from '../../assets/iconLogo.png'
import {getUsers} from '../services/admin-services/manage-users'
import {Link} from 'react-router-dom'
const Users = () => {
  const [allUsers, setAllUsers] = useState([])
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData

  useEffect(() => {
    getAllUsers()
  }, [])
  const getAllUsers = async () => {
    try {
      const result = await getUsers(accessToken)
      if (result.status === true) {
        setAllUsers(result.users.data)
      }
    } catch (err) {
      console.log('getAllCard err', err)
    }
  }

  return (
    <>
      {/* Header Start */}
      <KTCard className=' mb-20 min-h-600px'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>Users</div>
        </div>
        {/* Header End */}
        {/* Table Start */}
        <KTCardBody className='py-4 mb-4 scroll_data'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer '>
              <tbody className='text-gray-600 fw-bold'>
                {allUsers?.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td className=' min-w-125px'>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-75px overflow-hidden me-3'>
                            <a href='#'>
                              <div className='symbol-label'>
                                {/* 'thumb/' + */}
                                {item.profile_image ? (
                                  <img
                                    src={
                                      item.profile_image?.full_path + item.profile_image?.file_name
                                    }
                                    alt='not found'
                                    className=' w-100 h-100'
                                  />
                                ) : (
                                  <img src={logoImg} alt='not found' className=' w-100 h-100' />
                                )}
                                {/* <img
                                  src={
                                    item.profile_image?.full_path + item.profile_image?.file_name
                                  }
                                  alt=''
                                  className=' w-100'
                                /> */}
                              </div>
                            </a>
                          </div>
                          <div className='d-flex flex-column'>
                            <Link
                              to={`/manage-users/${item.id}`}
                              state={{item: item}}
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              {item.first_name == '' && item.first_name == null
                                ? 'no name'
                                : `${item.first_name} ${item.last_name}`}
                            </Link>
                            <span>{item.email}</span>
                          </div>
                        </div>
                      </td>
                      {/* <td className=' min-w-100px'>
                        <img
                          src={item.cardImage}
                          alt=''
                          className='img-fluid w-50 h-70px'
                          style={{borderRadius: '50%'}}
                        />
                      </td>
                      <td className='min-w-100px'>
                        {item.lastN}
                        <br />
                        <small className='min-w-100px'>{item.email}</small>
                      </td> */}

                      <td className='text-center min-w-100px'>
                        {item.color === 'red' ? (
                          <span className={`badge badge-danger`}> InActive</span>
                        ) : (
                          <span className={`badge badge-success`}> Active</span>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination Start*/}
          {/* <div className='row mt-3'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <ul class='pagination'>
                <li class='page-item previous disabled'>
                  <span class='page-link'>Previous</span>
                </li>
                <li class='page-item '>
                  <a href='#' class='page-link'>
                    1
                  </a>
                </li>
                <li class='page-item active'>
                  <a href='#' class='page-link'>
                    2
                  </a>
                </li>

                <li class='page-item next'>
                  <a class='page-link' href='#'>
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          {/* Pagination End*/}
        </KTCardBody>
      </KTCard>
      {/* Table End */}
    </>
  )
}

export default Users
