import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import DashBoard from '../../components/DashBoard'
import UserCards from '../../components/manage-users/UserCards'
import ViewUser from '../../components/manage-users/ViewUser'
import ViewCard from '../../components/manage-cards/ViewCard'
import ViewContact from '../../components/ViewContact'

const PrivateRoutes = () => {
  const ManageUsers = lazy(() => import('../../components/manage-users/ManageUsers'))
  const ManageContacts = lazy(() => import('../../components/manage-contacts/ManageContacts'))
  const ManageCards = lazy(() => import('../../components/manage-cards/ManageCards'))
  const ManagePackages = lazy(() => import('../../components/ManagePackages'))
  const ManagePermissions = lazy(() => import('../../components/ManagePermissions'))
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        {/* <Route path='dashboard' element={<DashBoard />} /> */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules////////////////////////////////////////// */}
        <Route
          path='manage-users'
          element={
            <SuspensedView>
              <ManageUsers />
            </SuspensedView>
          }
        />
        <Route path='manage-users/:id' element={<ViewUser />} />
        <Route path='usercard/:id' element={<UserCards />} />
        <Route path='card/:card_id' element={<ViewCard />} />
        <Route
          path='manage-cards'
          element={
            <SuspensedView>
              <ManageCards />
            </SuspensedView>
          }
        />
        <Route
          path='manage-contacts'
          element={
            <SuspensedView>
              <ManageContacts />
            </SuspensedView>
          }
        />

        <Route path='cards/:contact_id/contacts' element={<ViewContact />} />
        {/* <Route
          path='manage-packages'
          element={
            <SuspensedView>
              <ManagePackages />
            </SuspensedView>
          }
        />
        <Route
          path='manage-permissions'
          element={
            <SuspensedView>
              <ManagePermissions />
            </SuspensedView>
          }
        /> */}

        {/* Lazy Modules////////////////////////////////////////// */}

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
