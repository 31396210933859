// import Axios from 'axios'
import {baseURL} from '../../BaseURL'

export const getUsers = async (accessToken, page, search) => {
  const response = await fetch(`${baseURL}/users/search?page=${page}`, {
    body: JSON.stringify({
      search: search,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })

  const result = await response.json()
  console.log('server', result)
  return result
}
export const getSingleUser = async (id, accessToken) => {
  const response = await fetch(`${baseURL}/users/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  console.log(result)
  return result
}
export const createUser = async (
  userProfile,
  userFirstName,
  userLastName,
  userEmail,
  password,
  userStatus,
  accessToken
) => {
  let formData = new FormData()
  formData.append('profile_image', userProfile)
  formData.append('first_name', userFirstName)
  formData.append('last_name', userLastName)
  formData.append('email', userEmail)
  formData.append('password', password)
  formData.append('status', userStatus)
  const response = await fetch(`${baseURL}/users`, {
    body: formData,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const result = await response.json()
  return result
}

export const updateUser = async (
  id,
  accessToken,
  userProfile,
  userFirstName,
  userLastName,
  userEmail,
  password,
  userStatus
) => {
  let formData = new FormData()
  formData.append('profile_image', userProfile)
  formData.append('first_name', userFirstName)
  formData.append('last_name', userLastName)
  formData.append('email', userEmail)
  if (password !== '' && password !== 'undefined') {
    formData.append('password', password)
  }
  formData.append('status', userStatus)
  const response = await fetch(`${baseURL}/users/${id}`, {
    body: formData,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      // 'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  console.log('name', userFirstName)
  return result
}

export const deleteUser = async (accessToken, id) => {
  const response = await fetch(`${baseURL}/users/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}

// export const updateImage = async (id, accessToken, updateProfile) => {
//   let formData = new FormData()
//   console.log('sdds', updateProfile)
//   formData.append('profile_image', updateProfile)
//   const response = await fetch(`${baseURL}/users/${id}`, {
//     body: formData,
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       Authorization: 'Bearer' + '  ' + accessToken,
//     },
//   })
//   const result = await response.json()
//   return result
// }
export const filterUsers = async (accessToken, page, fromDate, toDate, status) => {
  const response = await fetch(`${baseURL}/users/search?page=${page}`, {
    body: JSON.stringify({
      from_date: fromDate,
      to_date: toDate,
      status: status,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}
