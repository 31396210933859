/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/manage-users'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'User Management'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/manage-cards'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'Manage User Cards'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/manage-contacts'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'Manage Contacts'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/manage-packages'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'Manage Packages' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/manage-permissions'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'Manage Permissions' })}
        fontIcon='bi-app-indicator'
      /> */}
    </>
  )
}
