import React, {useState, useEffect} from 'react'
import {KTCardBody, KTCard} from '../../_metronic/helpers'
import {useParams, Link, useLocation} from 'react-router-dom'
import {getSingleCard} from '../services/admin-services/manage-cards'
import {BsArrowLeft} from 'react-icons/bs'
import {Loading} from '../loading/Loading'

import '../style.css'
import { imgBaseURL } from '../services/BaseURL'
const ViewCard = () => {
  const [card, setCard] = useState([])
  const [loadingState, setLoadingState] = useState(false)
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  const {card_id} = useParams()
  const location = useLocation()
  const [locationState, setLocationState] = useState({item: ''})
  useEffect(() => {
    // if (location.state) {
    //   let _state = location.state
    //   setLocationState(_state)
    // } else {
    async function getUser() {
      try {
        const result = await getSingleCard(card_id, accessToken)
        setLoadingState(true)
        if (result.status === true) {
          setLoadingState(false)
          setCard(result.card)
        }
      } catch (err) {
        setLoadingState(true)
        console.log('user err', err)
      }
    }
    getUser()
    // }
    // }, [location])
  }, [card_id])
  //

  console.log('fayyaz',card)
  return (
    <>
      <div className='row position-relative'>
        <div className='col-md-4 col-12' style={{position: 'sticky', top: 0}}>
          <KTCard className=' mb-5 pb-5 px-0'>
            <KTCardBody className='py-2 mb-4 px-2 '>
              <h5 className='text-center  p-4 rounded' style={{backgroundColor: ' #BAE409'}}>
                <Link
                  to={
                    {pathname: '/manage-cards', state: {prevPath: location.pathname}} ??
                    '/manage-cards'
                  }
                >
                  <BsArrowLeft size={20} />
                  &nbsp;
                </Link>
                {locationState.item.title ?? card.title} &nbsp;Details
              </h5>
              <div className='col-12 text-center'>
                <h5>Card Logo</h5>
                <div className='  my-3'>
                 
                  <img
                    src={
                       imgBaseURL + card.logo?.full_path + card.logo?.file_name
                    }
                    alt=''
                    className='img-fluid w-150px  h-150px'
                    style={{borderRadius: '70%'}}
                  />
                </div>
              </div>
              <div className='col-12 text-center'>
                <h5 className=''>Card QR code</h5>
                <div className='  my-3'>
                  <img
                    src={imgBaseURL+ card.qr_code}
                    alt=''
                    className='img-fluid w-150px rounded  h-150px'
                  />
                </div>
              </div>

              <div className='col-12 text-center'>
                <h5>Card Banner</h5>
                <div className='  my-3'>
                  <img
                    src={ imgBaseURL+card.banner?.full_path + card.banner?.file_name
                    }
                    alt=''
                    className='img-fluid w-200px rounded  h-150px'
                  />
                  {/* ) : (
                  <img src={dummyCard} alt='not found' className='img-fluid w-150px h-70px ' />
                )} */}
                </div>
              </div>
            </KTCardBody>
          </KTCard>
          <KTCard className=' mb-5 py-5'>
            <KTCardBody className='py-2 '>
              <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Status</th>
              <td>
                {locationState.item.status ?? card.status === 'active' ? (
                  <span className='badge badge-success'>Active</span>
                ) : locationState.item.status ?? card.status === 'pending' ? (
                  <span className='badge badge-warning me-1 mb-1'>Pending</span>
                ) : locationState.item.status ?? card.status === 'disabled' ? (
                  <span className='badge badge-secondary me-1'>inActive</span>
                ) : locationState.item.status ?? card.status === 'disabled' ? (
                  <span className='badge badge-danger'>delete</span>
                ) : null}
              </td>
            </KTCardBody>
          </KTCard>
        </div>
        <div className='col-md-8 col-12'>
          <KTCard className=' mb-5 py-5'>
            <KTCardBody className='pt-4 pb-1 mb-4'>
              <div className='min-w-150px fw-bolder mb-2 fs-6 text-gray-800'>Headline</div>
              <td className='rounded p-3' style={{backgroundColor: '#f5f5f5'}}>
                {locationState.item.headline ?? card.headline}
              </td>
            </KTCardBody>
          </KTCard>
          <div className='row'>
            <div className='col'>
              <KTCard className=' mb-5 pb-5 px-0'>
                <h5 className='text-center  p-4 m-2 rounded' style={{backgroundColor: ' #BAE409'}}>
                  Personal Detail
                </h5>
                <KTCardBody className='py-2 px-2 scroll_data'>
                  <div class='table-responsive px-4'>
                    <table class='table table-row-dashed table-row-gray-300 gy-3 '>
                      <tbody>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Prefix</th>
                          <td>{locationState.item.card_meta?.prefix ?? card.card_meta?.prefix}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Suffix</th>
                          <td>{locationState.item.card_meta?.suffix ?? card.card_meta?.suffix}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Title</th>
                          <td>{locationState.item.title ?? card.title}</td>
                        </tr>

                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>First Name</th>
                          <td>{locationState.item.first_name ?? card.first_name}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Maiden Name</th>
                          <td>
                            {locationState.item.card_meta?.maiden_name ??
                              card.card_meta?.maiden_name}
                          </td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Middle Name</th>
                          <td>
                            {locationState.item.card_meta?.middle_name ??
                              card.card_meta?.middle_name}
                          </td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Last Name</th>
                          <td>{locationState.item.last_name ?? card.last_name}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                            preferred Name
                          </th>
                          <td>
                            {locationState.item.card_meta?.preferred_name ??
                              card.card_meta?.preferred_name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </KTCardBody>
              </KTCard>
            </div>
            <div className='col'>
              <KTCard className=' mb-5 pb-5 px-0'>
                <h5 className='text-center m-2 p-4 rounded' style={{backgroundColor: ' #BAE409'}}>
                  Company Detail
                </h5>
                <KTCardBody className='py-2 px-2 scroll_data'>
                  <div class='table-responsive px-4'>
                    <table class='table table-row-dashed table-row-gray-300 gy-3 '>
                      <tbody>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                            Accreditation
                          </th>
                          <td>
                            {locationState.item.card_meta?.accreditation ??
                              card.card_meta?.accreditation}
                          </td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Company</th>
                          <td>{locationState.item.company ?? card.company}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder  fs-6 text-gray-800'>Department</th>
                          <td>{locationState.item.department ?? card.department}</td>
                        </tr>

                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Theme</th>
                          <td>{locationState.item.theme_style ?? card?.theme_style}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Pronouns</th>
                          <td>
                            {locationState.item.card_meta?.pronouns ?? card.card_meta?.pronouns}
                          </td>
                        </tr>

                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Card Status</th>
                          <td>
                            {locationState.item.status ?? card.status === 'active' ? (
                              <span className='badge badge-success'>Active</span>
                            ) : locationState.item.status ?? card.status === 'pending' ? (
                              <span className='badge badge-warning me-1 mb-1'>Pending</span>
                            ) : locationState.item.status ?? card.status === 'disabled' ? (
                              <span className='badge badge-secondary me-1'>inActive</span>
                            ) : locationState.item.status ?? card.status === 'disabled' ? (
                              <span className='badge badge-danger'>delete</span>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </KTCardBody>
              </KTCard>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <KTCard className=' mb-5 pb-5 px-0'>
                <KTCardBody className='py-2 px-2'>
                  <h5 className='text-center  p-4 rounded' style={{backgroundColor: ' #BAE409'}}>
                    Locations
                  </h5>
                  <div class='table-responsive px-4'>
                    <table class='table table-row-dashed table-row-gray-300 gy-3 '>
                      <tbody>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Country</th>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Province</th>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>City</th>
                        </tr>
                        {locationState.locations ??
                          card.locations?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.country}</td>
                              <td>{item.province}</td>
                              <td>{item.city}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {loadingState && <Loading />}
                </KTCardBody>
              </KTCard>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <KTCard className=' mb-10 pb-5 px-0'>
                <h5 className='text-center m-2  p-4 rounded' style={{backgroundColor: ' #BAE409'}}>
                  Social Links
                </h5>
                <KTCardBody className='py-2 px-2 scroll_data'>
                  <div class='table-responsive px-4'>
                    {/* <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Prefix</th> */}
                   
                     { card.cards_items?.map((item, i) => (
                        <div key={i} className='first-division mb-3 col-md-12 col-7 mt-1'>
                          {item.type === 'facebook' ? (
                            <>
                              {/* <label htmlFor=''>FaceBook:&nbsp;</label> */}
                              <a href={item.value}>{item.value}</a>
                            </>
                          ) : item.type === 'twitter' ? (
                            <>
                              {/* <label htmlFor=''>Twitter:&nbsp;</label> */}
                              <a href={item.value}>{item.value}</a>
                            </>
                          ) : item.type === 'instagram' ? (
                            <>
                              {/* <label htmlFor=''>Instagram:&nbsp;</label> */}
                              <a href={item.value}>{item.value}</a>
                            </>
                          ) : item.type === 'linkedin' ? (
                            <>
                              {/* <label htmlFor=''>Linkedin:&nbsp;</label> */}
                              <a href={item.value}>{item.value}</a>
                            </>
                          ) : item.type === 'youtube' ? (
                            <>
                              {/* <label htmlFor=''>Linkedin:&nbsp;</label> */}
                              <a href={item.value}>{item.value}</a>
                            </>
                          ) : item.type === 'google' ? (
                            <>
                              {/* <label htmlFor=''>Google:&nbsp;</label> */}
                              <a href={item.value}>{item.value}</a>
                            </>
                          ) : null}
                        </div>
                      ))}
                  </div>
                </KTCardBody>
              </KTCard>
            </div>
            <div className='col'>
              <KTCard className=' mb-10 pb-5 px-0'>
                <h5 className='text-center  p-4 m-2 rounded' style={{backgroundColor: ' #BAE409'}}>
                  Contact Detail
                </h5>
                <KTCardBody className='py-2 px-2 scroll_data'>
                  <div class='table-responsive px-4'>
                    <table class='table table-row-dashed table-row-gray-300 gy-3 '>
                      <tbody>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Email</th>
                          <td>{locationState.item.user?.email ?? card.user?.email}</td>
                        </tr>
                        <tr>
                          <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Phone</th>
                          {/* <td>{locationState.item.user?.phone ?? card.user?.phone}</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {loadingState && <Loading />}
                </KTCardBody>
              </KTCard>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewCard
