import React, {useState, useEffect} from 'react'
import {KTCardBody, KTCard} from '../../_metronic/helpers'

import {Link, useParams} from 'react-router-dom'

import {getSingleUser} from '../services/admin-services/manage-users'
import {Loading} from '../loading/Loading'
const UserCards = () => {
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  const [loadingState, setLoadingState] = useState(false)
  const [userCards, setUserCards] = useState([])
  const {id} = useParams()
  useEffect(() => {
    async function getCards() {
      try {
        const result = await getSingleUser(id, accessToken)
        setLoadingState(true)
        if (result.status === true) {
          setLoadingState(false)
          setUserCards(result.user)
        }
      } catch (err) {
        setLoadingState(false)
        console.log('user err', err)
        // setErrorModelText(err.response.data.message)
        // setErrorModel(true);
      }
    }
    getCards()
  }, [id])
  console.log('user card', userCards)
  return (
    <>
      {userCards.cards?.map((item, index) => (
        <KTCard className=' mb-10 py-5'>
          {loadingState && <Loading />}
          {/* <Loading /> */}
          <KTCardBody className='py-4 mb-4'>
            <div key={index} className='row text-sm-center'>
              <h1 className='mb-8'>{item.card_name}</h1>
              <div className='col-md-4 col-12'>
                <h5>Card Logo</h5>
                <div className=' min-w-100px my-3'>
                  <img
                    src={item.logo?.full_path + item.logo?.file_name}
                    alt=''
                    className='img-fluid w-50  h-150px'
                    style={{borderRadius: '70%'}}
                  />
                </div>
              </div>
              <div className='col-4'>
                <h5>Card Banner</h5>
                <div className=' min-w-100px my-3'>
                  <img
                    src={item.banner?.full_path + item.banner?.file_name}
                    alt=''
                    className='img-fluid w-75 rounded  h-150px'
                  />
                </div>
              </div>

              <div className='col-3'>
                <h5 className=''>Card QR code</h5>
                <div className=' min-w-100px my-3'>
                  <img src={item.qr_code} alt='' className='img-fluid w-75 rounded  h-150px' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='mt-8 col-12'>
                <tr>
                  <td className='fs-3 min-w-150px fw-bold'>Card Status</td>
                  <td className='fs-6 min-w-100px'>
                    {item.status === 'active' ? (
                      <span className='badge badge-success'>Active</span>
                    ) : item.status === 'pending' ? (
                      <span className='badge badge-warning me-1 mb-1'>Pending</span>
                    ) : item.status === 'disabled' ? (
                      <span className='badge badge-secondary me-1'>inActive</span>
                    ) : item.status === 'disabled' ? (
                      <span className='badge badge-danger'>delete</span>
                    ) : null}

                    {/* <span className='fs-4'>{item.status}</span> */}
                  </td>
                </tr>
              </div>
              <div>
                <Link to={`/card/${item.id}`} className='text-primary'>
                  {/* <li className='menu-link px-3 mb-2' style={{cursor: 'pointer'}}> */}
                  View full detail
                  {/* </li> */}
                </Link>
              </div>
            </div>
          </KTCardBody>
        </KTCard>
      ))}
    </>
  )
}

export default UserCards
