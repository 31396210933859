import React, {useState, useEffect} from 'react'

import {Link, useParams, useLocation} from 'react-router-dom'
import {getSingleUser} from '../services/admin-services/manage-users'
import logoImg from '../../assets/iconLogo.png'
import {BsArrowLeft} from 'react-icons/bs'
import {BsArrowRight} from 'react-icons/bs'
import {Loading} from '../loading/Loading'
import dummyCard from '../../assets/dammyCard.PNG'
import { imgBaseURL } from '../services/BaseURL'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
const ViewUser = () => {
  const [singleUser, setSingleUser] = useState([])
  const [openModel, setOpenModel] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  const {id} = useParams()
  const location = useLocation()
  const [locationState, setLocationState] = useState({item: ''})

  useEffect(() => {
    // if (location.state) {
    //   let _state = location.state
    //   setLocationState(_state)
    // } else {

    async function getUser() {
      try {
        setLoadingState(true)
        const result = await getSingleUser(id, accessToken)
        console.log('aa', result)
        if (result.status === true) {
          setLoadingState(false)
          setSingleUser(result.user)
        }
      } catch (err) {
        setLoadingState(false)
        console.log('user err', err)
      }
    }
    getUser()
    // }
  }, [id])
  // }, [location])

  console.log(singleUser)
  ///////////Date Formatter///////////
  const dateFormateHandler = (createdAt) => {
    let today = new Date(createdAt)

    let date = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
    }).format(today)
    return date
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {/* <Link to='/manage-users'> */}
              <Link
                to={
                  {pathname: '/manage-users', state: {prevPath: location.pathname}} ??
                  '/manage-users'
                }
              >
                <BsArrowLeft size={20} />
              </Link>
              &nbsp; Profile Details
            </h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='me-7 mb-7'>
            
            {singleUser?.profile_image ? (
              <div className='symbol symbol-150px  symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                      imgBaseURL+singleUser.profile_image?.full_path + singleUser.profile_image?.file_name
                  }
                  className='h-100'
                  alt='no image found'
                />

                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            ) : (
              <img src={logoImg} alt='not found' className='img-fluid w-150px h-150px' />
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-bold text-muted'>Full Name</label>

            <div className='col-8'>
              <span className='fw-bolder fs-6 text-dark'>{`${
                locationState.item.first_name ?? singleUser.first_name
              } ${locationState.item.last_name ?? singleUser.last_name} `}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-bold text-muted'>Email</label>

            <div className='col-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {locationState.item.email ?? singleUser.email},
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-bold text-muted'>Status</label>

            <div className='col-8 fv-row'>
              <span className='fw-bold fs-6'>
                {locationState.item.status === 'active' || singleUser.status === 'active' ? (
                  <span className='badge badge-success'>Active</span>
                ) : locationState.item.status === 'pending' || singleUser.status === 'pending' ? (
                  <span className='badge badge-warning me-1 mb-1'>Pending</span>
                ) : locationState.item.status === 'disabled' || singleUser.status === 'disabled' ? (
                  <span className='badge badge-secondary me-1'>inActive</span>
                ) : locationState.item.status === 'deleted' || singleUser.status === 'deleted' ? (
                  <span className='badge badge-danger'>delete</span>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            {/* <h3 className='fw-bolder m-0'> */}
            <Link to={`/manage-users/${singleUser.id}`} onClick={() => setOpenModel(true)}>
              View Cards&nbsp;
              <BsArrowRight size={15} />
            </Link>
            {/* </h3> */}
          </div>
        </div>
      </div>
      {openModel && (
        <Modal size='lg' isOpen={openModel} centered={true} toggle={null}>
          <ModalHeader toggle={() => setOpenModel(!openModel)}>
            <h5 className='modal-title'>Cards</h5>
          </ModalHeader>
          <ModalBody className='justify-content-center'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer '>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>Card Banner</th>
                    <th className='text-center min-w-100px'>Title</th>
                    {/* <th className='text-center min-w-100px'>User Email</th> */}
                    <th className='text-center min-w-100px'>Status</th>
                    <th className='text-center min-w-100px'>Joined Date</th>
                    <th className='text-center min-w-100px'>Contacts</th>
                    <th className='text-center min-w-100px'>Card Detail</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                  {/* {cardsFound ? ( */}
                  {singleUser.cards.length > 0 ? (
                    singleUser.cards.map((item, index) => (
                      <tr key={index}>
                        {item.banner ? (
                          <Link to={`/card/${item.id}`} state={{item: item}}>
                            <td className=' min-w-100px'>
                              <img
                                src={imgBaseURL+item.banner?.full_path + item.banner?.file_name}
                                alt=''
                                className='img-fluid w-150px h-70px rounded'
                              />
                            </td>
                          </Link>
                        ) : (
                          <Link to={`/card/${item.id}`} state={{item: item}}>
                            <td className=' min-w-100px'>
                              <img
                                src={dummyCard}
                                alt='not found'
                                className='img-fluid w-150px h-70px rounded'
                              />
                            </td>
                          </Link>
                        )}
                        {item.title === '' ? (
                          <td className=' min-w-100px text-center'>No Title</td>
                        ) : (
                          <td className=' min-w-100px text-center'>{item.title}</td>
                        )}
                        {/* <td className='text-center min-w-100px  ' style={{cursor: 'pointer'}}>
                          <Link to={`/manage-users/${item.user.id}`} className='text-primary'>
                            {item.user.email}
                          </Link>
                        </td> */}
                        <td className='text-center min-w-100px'>
                          <div>
                            {item.status === 'active' ? (
                              <span className='badge badge-success'>Active</span>
                            ) : item.status === 'pending' ? (
                              <span className='badge badge-warning me-1 mb-1'>Pending</span>
                            ) : item.status === 'disabled' ? (
                              <span className='badge badge-secondary me-1'>inActive</span>
                            ) : item.status === 'deleted' ? (
                              <span className='badge badge-danger'>delete</span>
                            ) : null}
                          </div>
                        </td>
                        <td className='text-center min-w-100px'>
                          {dateFormateHandler(item.created_at)}
                        </td>
                        <td className='text-center min-w-100px'>
                          <Link to={`/cards/${item.id}/contacts`}>View</Link>
                        </td>
                        <td className='text-center min-w-100px'>
                          <Link to={`/card/${item.id}`} state={{item: item}}>
                            View
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='d-flex justify-content-center'>
                      <td className='text-center min-w-100px '>Record not found</td>
                    </tr>
                  )}
                </tbody>
                {loadingState && <Loading />}
              </table>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ViewUser
